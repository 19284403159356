var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('my-nav-bar', {
    attrs: {
      "title": _vm.clientName,
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container safe-area-padding-bottom"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm._l(_vm.list, function (orderGoods, index) {
    return _c('div', {
      key: index,
      staticClass: "order-goods"
    }, [_c('div', {
      staticClass: "goods-image"
    }, [_c('van-image', {
      attrs: {
        "src": orderGoods.image,
        "width": "2.13333rem",
        "height": "2.13333rem"
      }
    })], 1), _c('div', {
      staticClass: "goods-info"
    }, [_c('div', {
      staticClass: "goods-name"
    }, [_vm._v(_vm._s(orderGoods.name))]), _c('div', {
      staticClass: "gray-text"
    }, [_vm._v("规格：1 * " + _vm._s(orderGoods.size))]), _c('div', {
      staticClass: "gray-text"
    }, [_vm._v(" 数量： "), orderGoods.large_volume > 0 ? _c('span', [_vm._v(_vm._s(orderGoods.large_volume) + _vm._s(orderGoods.large_unit))]) : _vm._e(), orderGoods.least_volume > 0 ? _c('span', [_vm._v(_vm._s(orderGoods.least_volume) + _vm._s(orderGoods.least_unit))]) : _vm._e()])])]);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }