<template>
  <div>
    <my-nav-bar
      :title="clientName"
      left-text="返回"
      left-arrow
    />
    <div class="app-main-container safe-area-padding-bottom">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div v-for="(orderGoods, index) in list" :key="index" class="order-goods">
        <div class="goods-image">
          <van-image :src="orderGoods.image" width="2.13333rem" height="2.13333rem" />
        </div>
        <div class="goods-info">
          <div class="goods-name">{{ orderGoods.name }}</div>
          <div class="gray-text">规格：1 * {{ orderGoods.size }}</div>
          <div class="gray-text">
            数量：
            <span v-if="orderGoods.large_volume > 0">{{ orderGoods.large_volume }}{{ orderGoods.large_unit }}</span>
            <span v-if="orderGoods.least_volume > 0">{{ orderGoods.least_volume }}{{ orderGoods.least_unit }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getOrderDetail } from '@/api/business-take'

export default {
  components: { myNavBar },
  data() {
    return {
      clientName: this.$route.query.clientName,
      orderId: this.$route.query.orderId,
      businessId: this.$route.query.businessId,
      list: [],
      showEmpty: false
    }
  },
  created() {
    this.beginLoad()
    const params = {
      order_id: this.orderId,
      business_id: this.businessId
    }
    getOrderDetail(params).then(res => {
      this.list = res.data
      this.showEmpty = this.list.length === 0
      this.endLoad()
    })
  }
}
</script>

<style lang="scss" scoped>
  .app-main-container {
    padding: 10px;
  }
  .order-goods {
    display: flex;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #fff;
  }
  .order-goods:last-child {
    margin-bottom: 0!important;
  }
  .goods-name {
    word-break: break-all;
  }
  .goods-image {
    width: 80px;
    height: 80px;
  }
  .goods-info {
    margin-left: 10px;
  }
  .gray-text {
    color: #989898;
    font-size: 13px;
  }
</style>
